/**
 * Write-only the password as cookie
 */
import React, { useState } from 'react';
import { setSessionPassword } from '@mkitio/gatsby-theme-password-protect/src/utils/utils';
import { graphql, useStaticQuery } from "gatsby";

const styles = {
  wrapper: {
    height: '100vh',
    background: '#131314',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    width: '100%',
    height: '48px',
    borderRadius: '4px',
    textAlign: 'center',
    fontSize: '24px'
  },
  button: {
    width: '100%',
    height: '48px',
    background: '#f9e900',
    color: '#000',
    border: '1px solid rebeccapurple',
    borderRadius: '4px',
    marginTop: '16px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    //fontWeight: '300',
    fontFamily: 'sans-serif'
  },
  buttonHover: {
    background: '#fff',
    color: '#000000'
  }  
};

const PasswordProtect = () => {

  const [password, setPassword] = useState('');
  const [isButtonHovered, buttonHover] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      file(name: {eq: "avatar2"}) {
        publicURL
      }
    }`
  )

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <div style={styles.wrapper}>
      <img src={data.file.publicURL} alt="ROCKY Logo" width="175" height="175"></img>
      <h2 style={{ color: '#fff' }}>ROCKY Portfólio</h2>
      <h4 style={{ color: '#fff' }}>Insira a Senha</h4>

      <form onSubmit={onSubmit} style={{ width: '320px' }}>
        <input
          name="password"
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          style={styles.input}
        />

        <button
          type="submit"
          style={{
            ...styles.button,
            ...(isButtonHovered ? styles.buttonHover : null)
          }}
          onMouseEnter={() => buttonHover(true)}
          onMouseLeave={() => buttonHover(false)}
        >
          Acessar
        </button>
      </form>

    </div>
  );
};

export default PasswordProtect;
